<template>
  <div>
    <v-card>
      <v-card-title>
        <h3 class="ml-3">
          Modifier une certification
        </h3>
      </v-card-title>
      <v-skeleton-loader
        v-if="loading"
        type="article, actions"
      ></v-skeleton-loader>
      <template v-else>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col
                cols="6"
                md="6"
                sm="12"
              >
                <v-text-field
                  v-model="newCertification.nom"
                  label="Nom de la certification"
                  outlined
                  dense
                  placeholder="Nom de la certification"
                ></v-text-field>
              </v-col>
              <v-col
                md="6"
                cols="6"
                sm="12"
              >
                <v-text-field
                  v-model="newCertification.price"
                  :append-icon="icons.mdiCurrencyEur"
                  label="Prix de la certification"
                  outlined
                  dense
                  type="number"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                class="mb-5"
              >
                <v-select
                  v-model="newCertification.formations"
                  :items="formations"
                  :item-text="item => item.students ? `${item.nom} (${item.students.length} apprenants)` : `${item.nom}` "
                  chips
                  item-value="id"
                  :menu-props="{ maxHeight: '400' }"
                  label="Formations correspondantes"
                  multiple
                  persistent-hint
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="editCertification"
          >
            Modifier
          </v-btn>
          <v-dialog
            v-model="dialog"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                color="error"
                v-bind="attrs"
                outlined
                v-on="on"
              >
                Supprimer la certification
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Attention
              </v-card-title>

              <v-card-text>
                La suppression est définitive
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="deleteCertification"
                >
                  Supprimer
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </template>
    </v-card>
  </div>
</template>

<script>
import { mdiCurrencyEur } from '@mdi/js'

export default {
  components: {
  },
  data() {
    return {
      loading: true,
      dialog: false,
      formations: [],
      newCertification: {
        nom: '',
        formations: [],
        price: 0,
      },
      icons: {
        mdiCurrencyEur,
      },
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      Promise.all([this.fetchFormations(), this.fetchCertif()])
        .then(values => {
          [this.formations, this.newCertification] = values
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.loading = false
        })
    },
    async fetchFormations() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/formations`)

      return response.data
    },
    async fetchCertif() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/certifications/${this.$route.params.id}`)

      return response.data
    },
    reset() {
      this.newCertification.nom = ''
      this.newCertification.duree = ''
    },
    deleteCertification() {
      this.$http.delete(`${process.env.VUE_APP_API_URL}/certifications/${this.$route.params.id}`)
        .then(() => {
          this.alert = true
          this.alertColor = 'warning'
          this.alertMsg = 'Certification supprimée avec succés :)'
        }).catch(err => {
          console.log(err)
          this.alert = true
          this.alertColor = 'error'
          this.alertMsg = 'Erreur lors de la suppression de la certification :('
        })
    },
    editCertification() {
      this.$http.put(`${process.env.VUE_APP_API_URL}/certifications/${this.$route.params.id}`, this.newCertification)
        .then(res => {
          this.newCertification = res.data
          this.alert = true
          this.alertColor = 'success'
          this.alertMsg = 'Certification modifiée avec succés :)'
        }).catch(err => {
          console.log(err)
          this.alert = true
          this.alertColor = 'error'
          this.alertMsg = 'Erreur lors de la modification de la certification :('
        })
    },
  },
}
</script>

<style>

</style>
